import React from "react"

import "./landmarkCard.css"

const imageWidths = {
  small: 503, // Size for smaller screens
  small2x: 1006, // High-DPI for smaller screens
  medium: 194, // Size for larger screens
  medium2x: 388, // High-DPI for larger screens
}

const LandmarkCard = ({ feature }) => {
  const { name, description, image, link, credit } = feature.properties
  const { subfolders, fileName } = image
  const baseUrl = `https://upload.wikimedia.org/wikipedia/commons/`
  const thumbUrl = `${baseUrl}thumb/${subfolders}/${fileName}`
  const title = credit ? `${name} - ${credit}` : name
  return (
    <div className="landmark-card">
      <p>
        <a
          href={`${baseUrl}${subfolders}/${fileName}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            srcSet={`
            ${thumbUrl}/${imageWidths.small}px-${fileName} 503w,
             ${thumbUrl}/${imageWidths.small2x}px-${fileName} 1006w,
            ${thumbUrl}/${imageWidths.medium}px-${fileName} 194w,
             ${thumbUrl}/${imageWidths.medium2x}px-${fileName} 388w,
          `}
            sizes="(max-width: 575px) 503px, (min-width: 576px) 180px"
            src={`${thumbUrl}/${imageWidths.small}px-${fileName}`}
            alt={title}
            title={title}
            loading="lazy"
            onError={(e) => (e.target.style.display = "none")}
          />
        </a>
      </p>
      <div>
        <h3>
          {link ? (
            <a href={link} target="_blank" rel="noopener noreferrer">
              {name}
            </a>
          ) : (
            name
          )}
        </h3>
        {description && <p>{description}</p>}
      </div>
    </div>
  )
}

export default LandmarkCard
